import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AccountCircle,
  ArrowBack,
  ArrowForwardIos,
  Done,
  Event,
} from "@material-ui/icons";
import {
  formatoFecha,
  formatoFechaCorta,
} from "../../../config/reuserFunctions";
import { CustomChipStatus } from "../CustomComp";
import { makeStyles } from "@material-ui/core";
import { blueGrey, green } from "@material-ui/core/colors";
import { UPDATE_MESES_SUCURSAL } from "../../../gql/Empresa/sucursales";
import { useMutation } from "@apollo/client";
import RegistroSucursal from "./RegistroSucursal";

const useStyles = makeStyles({
  card: {
    padding: 8,
    cursor: "pointer",
    backgroundColor: blueGrey[50],
    color: blueGrey[600],
    borderRadius: "5px",
    transition: ".3s ease",
    "&:hover": {
      transform: "translate(3px, -5px)",
      boxShadow: "-3px 10px 10px rgba(0, 0, 0, 0.1)",
    },
  },
  cardActive: {
    padding: 8,
    cursor: "pointer",
    backgroundColor: green[50],
    color: green[900],
    borderRadius: "5px",
    transition: ".3s ease",
    "&:hover": {
      transform: "translate(3px, -5px)",
      boxShadow: "-3px 10px 10px rgba(0, 0, 0, 0.1)",
    },
  },
});

export default function DetallesSucursales({ data, refetch, setAlert }) {
  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h6">
          <b>Sucursales</b>
        </Typography>
        <Box mx={1} />
        <RegistroSucursal />
      </Box>

      <Grid container spacing={1}>
        {data.sucursales.map((res) => (
          <Grid item md={3} key={res._id}>
            <RenderSucursalCard
              empresa={data}
              sucursal={res}
              refetch={refetch}
              setAlert={setAlert}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const RenderSucursalCard = ({ empresa, sucursal, refetch, setAlert }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [meses, setMeses] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [updateMesesSucursal] = useMutation(UPDATE_MESES_SUCURSAL);

  if (!sucursal) return null;

  const saveMeses = async () => {
    try {
      setLoading(true);
      const result = await updateMesesSucursal({
        variables: {
          sucursalId: sucursal._id,
          meses: parseInt(meses),
        },
      });
      refetch();
      setLoading(false);
      setAlert({
        message: result.data.updateMesesSucursal.message,
        status: "success",
        open: true,
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log(error.networkError?.result);
      setAlert({
        message: error.message ? error.message : "hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <>
      <Box
        onClick={() => handleClickOpen()}
        className={sucursal.licencia_activa ? classes.cardActive : classes.card}
      >
        <Typography style={{ fontSize: 14 }} noWrap>
          <b>{sucursal.nombre_sucursal}</b>
        </Typography>
        <Typography style={{ fontSize: 14, lineHeight: 1 }} noWrap>
          {sucursal.descripcion}
        </Typography>
        <Box display="flex" alignItems="center" mt={1}>
          <AccountCircle fontSize="small" />
          <Box mr={1} />
          <Typography style={{ fontSize: 14 }} noWrap>
            {sucursal.usuario_sucursal}
          </Typography>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => handleClose()}
        onClick={(e) => e.stopPropagation()}
        BackdropProps={{
          invisible: true,
        }}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Box mx={0.5} />
            <Typography
              variant="h6"
              component="div"
              color="textSecondary"
              style={{ display: "flex", alignItems: "center" }}
            >
              {empresa.nombre_empresa}{" "}
              <ArrowForwardIos fontSize="small" style={{ margin: "0 8px" }} />
              <span style={{ color: "black" }}>{sucursal.nombre_sucursal}</span>
            </Typography>
            <Box mx={0.5} />
            <Chip
              icon={<Event />}
              label={formatoFechaCorta(sucursal.fecha_registro)}
              size="small"
            />
          </Box>
        </DialogTitle>
        <DialogContent style={{ width: "60vw" }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Typography>
                <b>Nombre Sucursal: </b>
                {sucursal.nombre_sucursal}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>
                <b>ID: </b>
                {sucursal._id}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography>
                <b>Descripción: </b>
                {sucursal.descripcion}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2} mb={1}>
            <Typography variant="h6">
              <b>Encargado</b>
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Typography>
                <b>ID: </b>
                {sucursal.id_usuario}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Número usuario: </b>
                {sucursal.numero_usuario}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Usuario: </b>
                {sucursal.usuario_sucursal}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2} mb={1}>
            <Typography variant="h6">
              <b>Licencia</b>
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid item>
              <CustomChipStatus status={sucursal.licencia_activa} />
            </Grid>
            <Grid item md={12}>
              <Typography>
                <b>Código licencia: </b>
                {sucursal.codigo_licencia}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography>
                <b>Fecha inicio: </b>
                {formatoFecha(sucursal.fecha_inicio_licencia)}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography>
                <b>Vence el: </b>
                {formatoFecha(sucursal.fecha_vencimiento_licencia)}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                size="small"
                label="Añadir meses"
                fullWidth
                value={meses}
                InputProps={{
                  inputProps: { min: 1 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={loading}
                        onClick={() => saveMeses()}
                      >
                        {loading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <Done />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type="number"
                onChange={(e) => setMeses(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Drawer>
    </>
  );
};
