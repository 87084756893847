import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Delete } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { DELETE_UPDATE } from "../../gql/Updates/Updates";
import { useMutation } from "@apollo/client";

export default function Eliminar({ id, refetch, setAlert }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteUpdate] = useMutation(DELETE_UPDATE);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const result = await deleteUpdate({
        variables: {
          id,
        },
      });
      setLoading(false);
      refetch();
      setAlert({
        message: result.data.deleteUpdate.message,
        status: "success",
        open: true,
      });
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.networkError.result) {
        console.log(error.networkError.result);
      }
      setAlert({
        message: error.message ? error.message : "Hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <Button
        size="small"
        color="secondary"
        startIcon={<Delete />}
        onClick={handleClickOpen}
      >
        Eliminar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-delete-not"
      >
        <DialogTitle id="dialog-delete-not">
          {"¿Quieres eliminar esto?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="secondary"
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Delete />
              )
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
