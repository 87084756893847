import { gql } from "@apollo/client";

export const GET_UPDATES = gql`
  query GetUpdatesModel {
    getUpdatesModel {
      _id
      title
      description
      link
      launch_date
    }
  }
`;

export const CREAR_UPDATE = gql`
  mutation CreateUpdate($input: createUpdate) {
    createUpdate(input: $input) {
      message
    }
  }
`;

export const DELETE_UPDATE = gql`
  mutation DeleteUpdate($id: ID) {
    deleteUpdate(id: $id) {
      message
    }
  }
`;
