import { gql } from '@apollo/client';

export const OBTENER_SUCURSALES = gql`
    query ObtenerSucursalesEmpresa($id: ID!){
        obtenerSucursalesEmpresa(id: $id){
            _id
            nombre_sucursal
            descripcion
            usuario_sucursal
            direccion{
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
            }
            cuenta_sucursal {
                efectivo
            }         
        }
    }
`
export const OBTENER_DATOS_SUCURSAL = gql`
    query obtenerDatosSucursal($id: ID!){
        obtenerDatosSucursal(id: $id){
            _id
            nombre_sucursal
            descripcion
            usuario_sucursal
            direccion{
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
            }
            cuenta_sucursal {
                efectivo
            }     
            codigo_licencia
            fecha_inicio_licencia
            fecha_vencimiento_licencia
            licencia_activa    
        }
    }
`

export const CREAR_SUCURSAL = gql`
    mutation CrearSucursalExpress($meses: Int, $usuario: CrearUsuarioInput, $input: CrearSucursal, $empresa: ID!) {
        crearSucursalExpress(meses: $meses, usuario: $usuario, input: $input, empresa: $empresa) {
            _id
            nombre_sucursal
            descripcion
            usuario_sucursal
            password_sucursal
            codigo_licencia
            fecha_inicio_licencia
            fecha_vencimiento_licencia
            licencia_activa
        }
    }
`
export const ACTUALIZAR_SUCURSAL = gql`
  mutation editarSucursal($id: ID!, $input: EditarSucursal) {
    editarSucursal(id: $id, input: $input) {
         _id
            nombre_sucursal
            descripcion
            usuario_sucursal
            direccion{
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
            }
            cuenta_sucursal {
                efectivo
            }         
        }
    }
`
export const UPDATE_MESES_SUCURSAL = gql`
  mutation UpdateMesesSucursal($sucursalId: ID!, $meses: Int) {
    updateMesesSucursal(sucursalId: $sucursalId, meses: $meses) {
      message
    }
  }
`;

export const CREAR_MOVIMIENTO_CUENTA = gql`
    mutation CrearMovimientoCuenta($input: CrearMovimientoCuentaInput, $empresa: ID!, $sucursal: ID!, $tipo: Boolean){
        crearMovimientoCuenta(input: $input, empresa: $empresa, sucursal: $sucursal, tipo: $tipo){
            message
        }
    }
`;
export const EDITAR_MOVIMIENTO_CUENTA = gql`
    mutation EditarMovimientoCuenta($input: CrearMovimientoCuentaInput, $empresa: ID!, $sucursal: ID!, $tipo: Boolean){
        editarMovimientoCuenta(input: $input, empresa: $empresa, sucursal: $sucursal, tipo: $tipo){
            message
        }
    }
`;
export const CANCELAR_MOVIMIENTO_CUENTA = gql`
    mutation CancelarMovimientoCuenta($id_movimiento: ID!, $empresa: ID!, $sucursal: ID!){
        cancelarMovimientoCuenta(id_movimiento: $id_movimiento, empresa: $empresa, sucursal: $sucursal){
            message
        }
    }
`;
export const OBTENER_HISTORIAL_CUENTAS = gql`
    query ObtenerHistorialCuenta($empresa: ID!, $sucursal: ID!, $input: ObtenerHistorialCuenta, $tipo: Boolean, $limit: Int, $offset: Int){
        obtenerHistorialCuenta(empresa: $empresa, sucursal: $sucursal, input: $input, tipo: $tipo, limit: $limit, offset: $offset){
            saldo_en_caja
            totalDocs
            docs {
                _id
                tipo_movimiento
                id_User
                numero_caja
                id_Caja
                numero_usuario_creador
                nombre_usuario_creador
                horario_turno
                concepto_cuenta
                id_concepto_cuenta
                concepto_subcuenta
                id_concepto_subcuenta
                comentarios
                montos_en_caja { 
                    monto_efectivo{
                        monto 
                    }
                }
                fecha_movimiento{
                    completa
                }
                usuario_entrega {
                    _id
                    numero_usuario
                    nombre
                }
                origen_caja {
                    _id
                    numero_caja
                    activa
                }
                usuario_destino{
                    _id
                    numero_usuario
                    nombre
                }
                destino_caja {
                    _id
                    numero_caja
                    activa
                }
                fecha_movimiento_entrega
                status
                empresa
                sucursal 
            }            
        }
    }
`;