//layouts
import LayoutLogin from '../components/Layouts/LayoutLogin';
import LayoutRegistro from '../components/Layouts/LayoutRegistro';
import ErrorNotFound from '../components/Layouts/ErrorNotFound';

const routes = [
	{
		path: '/login',
		component: LayoutLogin,
		exact: true
	},
	{
		path: '/',
		component: LayoutRegistro,
		exact: true
	},
	{
		component: ErrorNotFound
	}
];

export default routes;
