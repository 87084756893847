import moment from "moment";

export const formatoHora = (hora) => {
  if (!hora) {
    return null;
  } else {
    var newtime = new Date(hora);
    return newtime.toLocaleTimeString("en-US", { hour12: "false" });
  }
};

export const formatoFecha = (fecha) => {
  if (!fecha) {
    return null;
  } else {
    var newdate = new Date(fecha);
    return newdate.toLocaleDateString("es-MX", {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

export const formatoFechaCorta = (fecha) => {
  if (!fecha) {
    return null;
  } else {
    /* var newdate = new Date(fecha); */
    return moment(fecha).locale("es-mx").format("ll");
    /*  return newdate.toLocaleDateString("es-mx", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }); */
  }
};