import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Add, Done } from "@material-ui/icons";
import { RECARGA_FOLIOS } from "../../../gql/Empresa/empresa";
import { useMutation } from "@apollo/client";

export default function RecargaFolios({ empresa, refetch, setAlert }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [folios, setFolios] = React.useState(0);

  const [recargaFolios] = useMutation(RECARGA_FOLIOS);

  const saveFoliosDB = async (e) => {
    try {
      setLoading(true);
      const result = await recargaFolios({
        variables: {
          empresa: empresa._id,
          folios,
        },
      });
      refetch();
      setLoading(false);
      setAlert({
        message: result.data.recargaFolios.message,
        status: "success",
        open: true,
      });
      setOpen(false);
      setFolios(0);
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log(error.networkError?.result);
      setAlert({
        message: error.message ? error.message : "hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <Box display="flex">
      <Button
        startIcon={<Add fontSize="small" />}
        color="primary"
        style={{ textTransform: "none" }}
        onClick={() => setOpen(!open)}
      >
        Recargar folios
      </Button>
      <Box
        ml={1}
        style={{
          width: open ? "150px" : "0px",
          opacity: open ? "1" : "0",
          transition: "width 0.5s, opacity 0.5s",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          value={folios}
          type="number"
          disabled={!open || loading}
          onChange={(e) => setFolios(parseInt(e.target.value))}
          fullWidth
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!open || loading}
                  onClick={() => saveFoliosDB()}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Done />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
}
