import React from "react";
import { Box, Button, Container, makeStyles, Typography } from "@material-ui/core";
import ImagenError404 from "../../img/Error404.png";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  imagen: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default function Error404() {
  const classes = useStyles();
  return (
    <Box>
      <Box top={5} right={5} px={5} position="absolute">
        <Button size="medium" startIcon={<ArrowBack />} variant="text" component={Link} to="/">
          Ir al panel
        </Button>
      </Box>
      <Container maxWidth="md">
        <Box height="80vh" mt={5}>
          <Box display="flex" justifyContent="center">
            <Box height="60vh">
              <img
                alt="error 404"
                src={ImagenError404}
                className={classes.imagen}
              />
            </Box>
          </Box>
          <Typography variant="h4" align="center">
            Lo sentimos, Esta pagina no existe
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
