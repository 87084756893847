import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREAR_EMPRESA } from "../../gql/Empresa/empresa";
import { Done } from "@material-ui/icons";

const initialState = {
  meses: 1,
  usuario: {
    empresa: "",
    sucursal: "",
    numero_usuario: 0,
    estado_usuario: true,
  },
  sucursal: {
    calle: "",
    no_ext: "",
    municipio: "",
    colonia: "",
    localidad: "",
    codigo_postal: 0,
    pais: "",
    estado: "",
  },
};

export default function RegistroEmpresa({
  open,
  handleClose,
  setAlert,
  refetch,
}) {
  const [data, setData] = React.useState(initialState);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef();

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    if (name === "meses") {
      setData({
        ...data,
        [name]: parseInt(value),
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const handleChangeFormUsuario = (e) => {
    const { name, value } = e.target;
    setData({ ...data, usuario: { ...data.usuario, [name]: value } });
  };
  const handleChangeFormSucursal = (e) => {
    const { name, value } = e.target;
    if (name === "codigo_postal") {
      setData({
        ...data,
        sucursal: { ...data.sucursal, [name]: parseInt(value) },
      });
    } else {
      setData({ ...data, sucursal: { ...data.sucursal, [name]: value } });
    }
  };

  const [crearEmpresa] = useMutation(CREAR_EMPRESA);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !data.nombre_empresa ||
        !data.nombre_dueno ||
        !data.telefono_dueno ||
        !data.correo_empresa ||
        !data.password ||
        !data.usuario.nombre ||
        !data.usuario.email ||
        !data.usuario.password ||
        !data.usuario.telefono ||
        !data.sucursal.nombre_sucursal
      ) {
        setError(true);
        return;
      }
      if (data.meses < 1) {
        setAlert({
          message: "los meses no pueden ser menor a 0",
          status: "error",
          open: true,
        });
        return;
      }
      let input = { ...data };
      input.usuario.repeatPassword = input.usuario.password;
      input.sucursal.usuario_sucursal = input.usuario.nombre;
      input.sucursal.localidad = input.sucursal.municipio;
      input.sucursal.password_sucursal = input.usuario.password;
      if (input.sucursal.codigo_postal) {
        input.sucursal.codigo_postal = parseInt(input.sucursal.codigo_postal);
      }
      setLoading(true);
      const result = await crearEmpresa({
        variables: {
          input,
        },
      });
      refetch();
      setLoading(false);
      setAlert({
        message: result.data.crearEmpresa.message,
        status: "success",
        open: true,
      });
      formRef.current.reset();
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result);
      }
      setLoading(false);
      setAlert({
        message: error.message ? error.message : "hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Registrar nueva empresa</DialogTitle>
        <DialogContent>
          <form
            id="form-empresa"
            autoComplete="off"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box my={1}>
                  <Typography>
                    <b>Datos Empresa</b>
                  </Typography>
                </Box>
                <TextField
                  autoFocus
                  required
                  variant="outlined"
                  margin="dense"
                  label="Empresa"
                  name="nombre_empresa"
                  fullWidth
                  onChange={handleChangeForm}
                  error={error && !data.nombre_empresa}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Propietario"
                  name="nombre_dueno"
                  fullWidth
                  onChange={handleChangeForm}
                  error={error && !data.nombre_dueno}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Telefono"
                  name="telefono_dueno"
                  fullWidth
                  onChange={handleChangeForm}
                  error={error && !data.telefono_dueno}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Correo electrónico"
                  name="correo_empresa"
                  fullWidth
                  onChange={handleChangeForm}
                  error={error && !data.correo_empresa}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Contraseña"
                  name="password"
                  fullWidth
                  onChange={handleChangeForm}
                  error={error && !data.password}
                />
                <Box my={1}>
                  <Typography>
                    <b>Datos Usuario</b>
                  </Typography>
                </Box>
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Nombre"
                  name="nombre"
                  fullWidth
                  onChange={handleChangeFormUsuario}
                  error={error && !data.usuario.nombre}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Correo Electrónico"
                  name="email"
                  fullWidth
                  onChange={handleChangeFormUsuario}
                  error={error && !data.usuario.email}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Telefono"
                  name="telefono"
                  fullWidth
                  onChange={handleChangeFormUsuario}
                  error={error && !data.usuario.telefono}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Contraseña"
                  name="password"
                  fullWidth
                  onChange={handleChangeFormUsuario}
                  error={error && !data.usuario.password}
                />
              </Grid>
              <Grid item md={6}>
                <Box my={1}>
                  <Typography>
                    <b>Datos Sucursal</b>
                  </Typography>
                </Box>
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Sucursal"
                  name="nombre_sucursal"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                  error={error && !data.sucursal.nombre_sucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Descripción"
                  name="descripcion"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Calle"
                  name="calle"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />

                <Box display="flex">
                  <TextField
                    variant="outlined"
                    margin="dense"
                    label="Num. Ext"
                    name="no_ext"
                    fullWidth
                    onChange={handleChangeFormSucursal}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    label="Num. Int"
                    name="no_int"
                    fullWidth
                    onChange={handleChangeFormSucursal}
                  />
                </Box>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Municipio o Localidad"
                  name="municipio"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Colonia"
                  name="colonia"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Código postal"
                  name="codigo_postal"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Estado"
                  name="estado"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Pais"
                  name="pais"
                  fullWidth
                  onChange={handleChangeFormSucursal}
                />
                <TextField
                  required
                  variant="outlined"
                  margin="dense"
                  label="Meses de licencia"
                  name="meses"
                  fullWidth
                  defaultValue={1}
                  InputProps={{ inputProps: { min: 1 } }}
                  type="number"
                  onChange={handleChangeForm}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              setData(initialState);
              formRef.current.reset();
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            form="form-empresa"
            type="submit"
            color="primary"
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
