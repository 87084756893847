import { Chip } from "@material-ui/core";
import { blueGrey, deepPurple, green } from "@material-ui/core/colors";
import { NotInterested, Storefront } from "@material-ui/icons";

export const CustomChipStatus = ({ status }) => {
  const label = status ? "Activa" : "Inactiva";
  const backgroundColor = status ? green[500] : blueGrey[100];
  const color = status ? "white" : "black";

  return <Chip label={label} size="small" style={{ backgroundColor, color }} />;
};

export const CustomChipTienda = ({ tienda }) => {
  const icon = tienda ? (
    <Storefront style={{ color: "#fff" }} />
  ) : (
    <NotInterested />
  );
  const backgroundColor = tienda ? deepPurple["A400"] : blueGrey[100];
  const color = tienda ? "white" : "black";

  return (
    <Chip
      icon={icon}
      label="Tienda"
      size="small"
      style={{ backgroundColor, color }}
    />
  );
};
