import React from "react";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ExitToApp, Launch } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import Empresas from "../../pages/RegistroEmpresas/Empresas";

function LayoutRegistro(props) {
  const sesion = sessionStorage.getItem("sesionCafi");

  if (!sesion) props.history.push("/login");

  return (
    <div>
      <NavegacionAdmin props={props} />
      <Empresas />
    </div>
  );
}

const NavegacionAdmin = ({ props }) => {
  const signOut = () => {
    sessionStorage.removeItem("sesionCafi");
    sessionStorage.removeItem("tokenCafi");
    props.history.push("/login");
  };

  return (
    <Box display="flex" px={2} pt={1} gridGap={10}>
      <Typography variant="h6" style={{ fontSize: 18 }}>
        Registro de Empresas
      </Typography>
      <Box flexGrow={1} />
      <Button
        size="medium"
        startIcon={<Launch />}
        href="https://cafipuntodeventa.com/"
        target="_blank"
        style={{ textTransform: "none" }}
      >
        CAFI Punto de venta
      </Button>
      <CerrarSesionDialog signOut={signOut} />
    </Box>
  );
};

const CerrarSesionDialog = ({ signOut }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="secondary"
        size="medium"
        startIcon={<ExitToApp />}
        variant="text"
        onClick={handleClickOpen}
        style={{ textTransform: "none" }}
      >
        Cerrar sesión
      </Button>
      <Dialog open={open} keepMounted onClose={handleClose}>
        <DialogTitle>¿Seguro que deseas cerrar sesión?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={signOut}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Cerrar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(LayoutRegistro);
