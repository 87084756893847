import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, IconButton, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Add, ArrowBack, Done } from "@material-ui/icons";
import { CREAR_SUCURSAL } from "../../../gql/Empresa/sucursales";

const initialState = {
  meses: 1,
  usuario: {
    empresa: "",
    sucursal: "",
    numero_usuario: 0,
    estado_usuario: true,
  },
  sucursal: {
    calle: "",
    no_ext: "",
    municipio: "",
    colonia: "",
    localidad: "",
    codigo_postal: 0,
    pais: "",
    estado: "",
  },
};

export default function RegistroSucursal({ id_empresa, setAlert, refetch }) {
  const [data, setData] = React.useState(initialState);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    if (name === "meses") {
      setData({
        ...data,
        [name]: parseInt(value),
      });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const handleChangeFormUsuario = (e) => {
    const { name, value } = e.target;
    setData({ ...data, usuario: { ...data.usuario, [name]: value } });
  };
  const handleChangeFormSucursal = (e) => {
    const { name, value } = e.target;
    if (name === "codigo_postal") {
      setData({
        ...data,
        sucursal: { ...data.sucursal, [name]: !value ? 0 : parseInt(value) },
      });
    } else {
      setData({ ...data, sucursal: { ...data.sucursal, [name]: value } });
    }
  };

  const [crearSucursalExpress] = useMutation(CREAR_SUCURSAL);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (
        !data.usuario.nombre ||
        !data.usuario.email ||
        !data.usuario.password ||
        !data.usuario.telefono ||
        !data.sucursal.nombre_sucursal
      ) {
        setError(true);
        return;
      }
      if (data.meses < 1) {
        setAlert({
          message: "los meses no pueden ser menor a 0",
          status: "error",
          open: true,
        });
        return;
      }
      let input = { ...data };
      input.usuario.repeatPassword = input.usuario.password;
      input.sucursal.usuario_sucursal = input.usuario.nombre;
      input.sucursal.localidad = input.sucursal.municipio;
      input.sucursal.password_sucursal = input.usuario.password;
      if (input.sucursal.codigo_postal) {
        input.sucursal.codigo_postal = parseInt(input.sucursal.codigo_postal);
      }
      setLoading(true);
      const result = await crearSucursalExpress({
        variables: {
          input: input.sucursal,
          usuario: input.usuario,
          meses: input.meses,
          empresa: id_empresa,
        },
      });
      refetch();
      setLoading(false);
      setAlert({
        message: result.data.crearSucursalExpress.message,
        status: "success",
        open: true,
      });
      formRef.current.reset();
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result);
      }
      setLoading(false);
      setAlert({
        message: error.message ? error.message : "hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <Button
        startIcon={<Add fontSize="small" />}
        color="primary"
        style={{ textTransform: "none" }}
        onClick={handleClickOpen}
      >
        Nueva
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => handleClose()}
        BackdropProps={{
          invisible: true,
        }}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => {
                handleClose();
                setData(initialState);
                formRef.current.reset();
              }}
              color="primary"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Nueva Sucursal</Typography>
            <Box flexGrow={1} />
            <Button
              form="form-sucursal"
              type="submit"
              variant="contained"
              disableElevation
              color="primary"
              size="small"
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ width: "40vw" }}>
          <form
            id="form-sucursal"
            autoComplete="off"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Sucursal"
              name="nombre_sucursal"
              fullWidth
              onChange={handleChangeFormSucursal}
              error={error && !data.sucursal.nombre_sucursal}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Descripción"
              name="descripcion"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Meses de licencia"
              name="meses"
              fullWidth
              defaultValue={1}
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              onChange={handleChangeForm}
            />
            <Box mb={1} mt={2}>
              <Typography>
                <b>Datos Usuario</b>
              </Typography>
            </Box>
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Nombre"
              name="nombre"
              fullWidth
              onChange={handleChangeFormUsuario}
              error={error && !data.usuario.nombre}
            />
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Correo Electrónico"
              name="email"
              fullWidth
              onChange={handleChangeFormUsuario}
              error={error && !data.usuario.email}
            />
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Telefono"
              name="telefono"
              fullWidth
              onChange={handleChangeFormUsuario}
              error={error && !data.usuario.telefono}
            />
            <TextField
              required
              variant="outlined"
              margin="dense"
              label="Contraseña"
              name="password"
              fullWidth
              onChange={handleChangeFormUsuario}
              error={error && !data.usuario.password}
            />
            <Box mb={1} mt={2}>
              <Typography>
                <b>Dirección</b>
              </Typography>
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="Calle"
              name="calle"
              fullWidth
              onChange={handleChangeFormSucursal}
            />

            <Box display="flex">
              <TextField
                variant="outlined"
                margin="dense"
                label="Num. Ext"
                name="no_ext"
                fullWidth
                onChange={handleChangeFormSucursal}
              />
              <TextField
                variant="outlined"
                margin="dense"
                label="Num. Int"
                name="no_int"
                fullWidth
                onChange={handleChangeFormSucursal}
              />
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="Municipio o Localidad"
              name="municipio"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Colonia"
              name="colonia"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Código postal"
              name="codigo_postal"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Estado"
              name="estado"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="Pais"
              name="pais"
              fullWidth
              onChange={handleChangeFormSucursal}
            />
          </form>
        </DialogContent>
      </Drawer>
    </div>
  );
}
