import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { Delete, Warning } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CLEAR_EMPRESA } from "../../gql/Empresa/empresa";

export default function ClearActions({ datos, refetch, setAlert }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [confirmText, setConfirmText] = React.useState("");
  const [action, setAction] = React.useState("");
  const [accionEmpresa] = useMutation(CLEAR_EMPRESA);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmText("");
    setAction("");
  };

  const handleDelete = async () => {
    try {
      //mutation
      setLoading(true);
      const result = await accionEmpresa({
        variables: {
          empresa: datos._id,
          accion: parseInt(action),
        },
      });
      refetch();
      setLoading(false);
      handleClose();
      setAlert({
        message: result.data.accionEmpresa.message,
        status: "success",
        open: true,
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.newtwork) console.log(error.newtwork.result);
      setAlert({
        message: error.message,
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <IconButton color="secondary" onClick={handleClickOpen}>
        <Warning />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="del-empresa-dialog"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="del-empresa-dialog">
          <Alert severity="error">
            <AlertTitle>Advertencia</AlertTitle>
            Las siguientes acciones son <strong>IRREVERSIBLES</strong>,
            asegurate que esto es lo que quieres hacer en:{" "}
            <strong>{datos.nombre_empresa}</strong>
          </Alert>
        </DialogTitle>
        <DialogContent>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={
                    <Typography color="textSecondary">
                      <strong>Restablecer</strong>: Esta acción borra todos los
                      datos, dejando solo los principales como cuando recién
                      inició
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="primary" />}
                  label={
                    <Typography
                      color="textSecondary"
                      style={{ margin: "10px 0px" }}
                    >
                      <strong>Restablecer con Catalogos</strong>: Esta acción
                      borra todos los datos, dejando solo Empresa, Sucursales,
                      Catalogos, Dejando tus productos y almacenes en 0
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="primary" />}
                  label={
                    <Typography color="error">
                      <strong>Eliminar Todo</strong>: Esta acción elimina
                      ABSOLUTAMENTE todos los datos.
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
            <Box my={2} />
            <Typography>
              Para realizar la acción teclea "realizar de forma permanente"
            </Typography>
            <TextField
              placeholder="realizar de forma permanente"
              variant="outlined"
              fullWidth
              size="small"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              disabled={!action}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="secondary"
            disabled={confirmText !== "realizar de forma permanente" || loading}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Delete />
              )
            }
          >
            Realizar acción
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
