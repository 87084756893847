import { gql } from "@apollo/client";
export const OBTENER_DATOS_EMPRESA = gql`
  query obtenerEmpresa($id: ID!) {
    obtenerEmpresa(id: $id) {
      _id
      tienda
      id_tienda
      nombre_empresa
      nombre_dueno
      telefono_dueno
      celular
      correo_empresa
      limite_timbres
      timbres_usados
      sello_sat
      nombre_cer
      nombre_key
      fecha_registro_sello_sat
      imagen
      nombre_fiscal
      rfc
      regimen_fiscal
      curp
      info_adicio
      valor_puntos
      direccion {
        calle
        no_ext
        no_int
        codigo_postal
        colonia
        municipio
        localidad
        estado
        pais
      }
      direccionFiscal {
        calle
        no_ext
        no_int
        codigo_postal
        colonia
        municipio
        localidad
        estado
        pais
      }
      datosBancarios {
        cuenta
        sucursal
        clave_banco
      }
      vender_sin_inventario
    }
  }
`;

export const CREAR_EMPRESA = gql`
  mutation CrearEmpresa($input: CrearEmpresa) {
    crearEmpresa(input: $input) {
      message
    }
  }
`;

export const CLEAR_EMPRESA = gql`
  mutation AccionEmpresa($empresa: ID!, $accion: Int!) {
    accionEmpresa(empresa: $empresa, accion: $accion) {
      message
    }
  }
`;

export const ACTIVAR_TIENDA = gql`
  mutation CrearTienda($empresa: ID!) {
    crearTienda(empresa: $empresa) {
      message
    }
  }
`;

export const ACTIVAR_EMPRESA = gql`
  mutation ActivarEmpresa($empresa: ID!) {
    activarEmpresa(empresa: $empresa) {
      message
    }
  }
`;

export const RECARGA_FOLIOS = gql`
  mutation recargaFolios($empresa: ID!, $folios: Int) {
    recargaFolios(empresa: $empresa, folios: $folios) {
      message
    }
  }
`;

export const ACTUALIZAR_EMPRESA = gql`
  mutation actualizarEmpresa($id: ID!, $sucursal: ID!, $input: EditarEmpresa) {
    actualizarEmpresa(id: $id, sucursal: $sucursal, input: $input) {
      _id
      nombre_empresa
      nombre_dueno
      telefono_dueno
      celular
      correo_empresa
      limite_timbres
      timbres_usados
      sello_sat
      nombre_cer
      nombre_key
      imagen
      nombre_fiscal
      rfc
      regimen_fiscal
      curp
      info_adicio
      valor_puntos
      direccion {
        calle
        no_ext
        no_int
        codigo_postal
        colonia
        municipio
        localidad
        estado
        pais
      }
      direccionFiscal {
        calle
        no_ext
        no_int
        codigo_postal
        colonia
        municipio
        localidad
        estado
        pais
      }
      datosBancarios {
        cuenta
        sucursal
        clave_banco
      }
    }
  }
`;

export const OBTENER_EMPREASAS = gql`
  query ObtenerEmpresas($offset: Int, $limit: Int, $filtro: String) {
    obtenerEmpresas(offset: $offset, limit: $limit, filtro: $filtro) {
      docs {
        sello_sat
        nombre_cer
        nombre_key
        imagen
        rfc
        regimen_fiscal
        curp
        _id
        tienda
        id_tienda
        nombre_empresa
        nombre_dueno
        telefono_dueno
        correo_empresa
        celular
        nombre_fiscal
        info_adicio
        sucursales_activas
        limite_sucursales
        limite_timbres
        timbres_usados
        empresa_activa
        createdAt
        fecha_registro
        direccion {
          calle
          no_ext
          no_int
          colonia
          codigo_postal
          municipio
          localidad
          estado
          pais
        }
        sucursales {
          _id
          nombre_sucursal
          descripcion
          usuario_sucursal
          id_usuario
          numero_usuario
          licencia_activa
          fecha_vencimiento_licencia
          fecha_inicio_licencia
          codigo_licencia
          createdAt
          fecha_registro
          id_empresa {
            _id
          }
          direccion {
            calle
            no_ext
            no_int
            colonia
            codigo_postal
            municipio
            localidad
            estado
            pais
          }
        }
      }
      totalDocs
    }
  }
`;
