import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Done } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import { CREAR_UPDATE } from "../../gql/Updates/Updates";
import { CircularProgress } from "@material-ui/core";

export default function FormNoti({refetch, setAlert}) {
  const [loading, setLoading] = React.useState(false);
  const [createUpdate] = useMutation(CREAR_UPDATE);
  const [data, setData] = React.useState({
    title: "",
    description: "",
    link: "",
  });

  const onEditorChange = (description) => {
    setData((st) => ({ ...st, description }));
  };

  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setData((st) => ({ ...st, [name]: value }));
  };

  const handleSaveData = async () => {
    try {
      const input = {
        ...data,
        description: data.description
          ? draftToHtml(convertToRaw(data.description.getCurrentContent()))
          : "",
      };
      console.log(input)
      setLoading(true);
      const result = await createUpdate({
        variables: {
          input,
        },
      });
      setLoading(false);
      refetch();
      setAlert({
        message: result.data.createUpdate.message,
        status: "success",
        open: true,
      });
      setData({
        title: "",
        description: "",
        link: "",
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
      if(error.networkError.result){
        console.log(error.networkError.result)
      }
      setAlert({
        message: error.message ? error.message : "Hubo un error",
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        placeholder="actualizacion 1.3.10"
        label="titulo/versión"
        variant="outlined"
        size="small"
        margin="dense"
        name="title"
        onChange={onChangeFields}
        value={data.title}
      />
      <TextField
        fullWidth
        label="Link"
        variant="outlined"
        size="small"
        margin="dense"
        onChange={onChangeFields}
        name="link"
        value={data.link}
      />
      <Editor
        editorState={data.description}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorChange}
        editorStyle={{ minHeight: 200 }}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : <Done />
          }
          onClick={() => handleSaveData()}
          variant="contained"
          disabled={loading}
        >
          Guardar
        </Button>
      </Box>
    </div>
  );
}
