import React from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { ArrowBack, Event } from "@material-ui/icons";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { formatoFechaCorta } from "../../../config/reuserFunctions";
import DetallesFacturacion from "./DetalleFacturacion";
import DetallesSucursales from "../Sucursales/DetallesSucursales";
import DengerZone from "./DangerZone";
import { CustomChipStatus, CustomChipTienda } from "../CustomComp";

export default function DetallesEmpresa({ data, refetch, setAlert }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!data) return null;

  /* const getAddress = (value) => {
    let address = "";
    if (!value) return address;
    if (value.calle) address = value.calle;
    if (value.no_ext) address = `${address} #${value.no_ext}`;
    if (value.municipio) address = `${address}, ${value.municipio}`;
    if (value.colonia) address = `${address} ${value.colonia}`;
    if (value.estado) address = `${address}, ${value.estado}`;
    if (value.pais) address = `${address}, ${value.pais}`;
    if (value.codigo_postal) address = `${address}, CP ${value.codigo_postal}`;
    return address;
  }; */

  return (
    <>
      <Box id={`fake-button-${data._id}`} onClick={() => handleClickOpen()} />
      <Drawer
        anchor="right"
        open={open}
        onClose={() => handleClose()}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Box mx={0.5} />
            <Typography variant="h6">{data.nombre_empresa}</Typography>
            <Box mx={0.5} />
            <CustomChipStatus status={data.empresa_activa} />
            <Box mx={0.5} />
            <CustomChipTienda tienda={data.tienda} />
            <Box mx={0.5} />
            <Chip
              icon={<Event />}
              label={formatoFechaCorta(data.fecha_registro)}
              size="small"
            />
          </Box>
        </DialogTitle>
        <DialogContent style={{ width: "60vw" }}>
          <Grid container spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography>
                <b>Propietario: </b>
                {` ${data.nombre_dueno}`}
              </Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography>
                <b>Correo: </b>
                {` ${data.correo_empresa}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>Tel/Cel: </b>
                {` ${data.celular || data.telefono_dueno}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <b>ID: </b>
                {` ${data._id}`}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <DetallesFacturacion data={data} refetch={refetch} setAlert={setAlert} />
          <br />
          <DetallesSucursales
            data={data}
            refetch={refetch}
            setAlert={setAlert}
          />
          <br />
          <DengerZone data={data} refetch={refetch} setAlert={setAlert} />
        </DialogContent>
      </Drawer>
    </>
  );
}
