import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { FileCopyOutlined, GetApp } from "@material-ui/icons";
import moment from "moment-timezone";
import DOMPurify from "dompurify";
import CustomLoader from "../../components/CustomLoader";
import ErrorPage from "../../components/ErrorPage";
import Eliminar from "./Eliminar";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";

export default function NotificationList({
  loading,
  error,
  data,
  refetch,
  setAlert,
}) {
  if (loading)
    return (
      <div>
        <Typography>
          <strong>Últimas notificaciones</strong>
        </Typography>
        <Box my={1}>
          <CustomLoader height="50vh" />
        </Box>
      </div>
    );
  if (error)
    return (
      <div>
        <Typography>
          <strong>Últimas notificaciones</strong>
        </Typography>
        <Box my={1}>
          <ErrorPage />
        </Box>
      </div>
    );

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
    setAlert({
      message: "Copiado a portapapeles",
      status: "success",
      open: true,
    });
  };

  const linkInstalador = "https://www.dropbox.com/scl/fi/7oo96of44px0h5l745tv3/cafi_instalador.exe?rlkey=0enp79gyfq1eci5wyiozyz7p1&st=093vtisy&dl=1";
  const linkBascula = "https://www.dropbox.com/scl/fi/bi2iderqiqvksgmxmrrys/bascula_service.exe?rlkey=x4je64j3ejdnqoxj2zwubcmgu&st=ftqx74o3&dl=1";

  return (
    <div>
      <Box mb={1}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          disabled
          value={linkInstalador}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Instalador</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleCopy(linkInstalador)}
                  size="small"
                >
                  <FileCopyOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mb={2}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          disabled
          value={linkBascula}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Bascula</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleCopy(linkBascula)}
                  size="small"
                >
                  <FileCopyOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Typography align="center">
        <strong>Últimas actualizaciones</strong>
      </Typography>
      <Box maxHeight={450} overflow="auto">
        {data.getUpdatesModel.map(
          ({ _id, title, description, link, launch_date }) => (
            <Box my={2} key={_id}>
              <Box display="flex" justifyContent="space-between">
                <Typography style={{ fontSize: 18 }}>
                  <strong>{title}</strong>
                </Typography>
                <Typography color="textSecondary">
                  {moment(launch_date).format("L")}
                </Typography>
              </Box>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              />
              <Box display="flex" justifyContent="space-between">
                <Button
                  href={link}
                  startIcon={<GetApp />}
                  color="primary"
                  size="small"
                >
                  instalador
                </Button>
                <Eliminar id={_id} refetch={refetch} setAlert={setAlert} />
              </Box>
              <Divider />
            </Box>
          )
        )}
      </Box>
    </div>
  );
}
