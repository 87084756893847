import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { Done, ToggleOff, ToggleOn } from "@material-ui/icons";
import { ACTIVAR_EMPRESA } from "../../gql/Empresa/empresa";

export default function ActivarEmpresa({ empresa, setAlert, refetch }) {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [activarEmpresa] = useMutation(ACTIVAR_EMPRESA);

  const handleChangeStatus = async (e) => {
    try {
      setLoading(true);
      const result = await activarEmpresa({
        variables: {
          empresa: empresa._id,
        },
      });
      refetch();
      setLoading(false);
      setAlert({
        message: result.data.activarEmpresa.message,
        status: "success",
        open: true,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result);
      }
      setLoading(false);
      setAlert({
        message: error.message ? error.message : "hubo un error",
        status: "error",
        open: true,
      });
    }
  };
  

  return (
    <div>
      <IconButton size="small" onClick={handleClickOpen}>
        {empresa.empresa_activa ? (
          <ToggleOn color="primary" style={{ fontSize: 40 }} />
        ) : (
          <ToggleOff style={{ fontSize: 40 }} />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {!empresa.empresa_activa
            ? "Activar Empresa"
            : "Inhabilitar esta empresa"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={handleChangeStatus}
            color={!empresa.empresa_activa ? "primary" : "secondary"}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : !empresa.empresa_activa ? (
                <Done />
              ) : null
            }
          >
            {!empresa.empresa_activa ? "Activar Empresa" : "Inhabilitar"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
