import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { formatoFechaCorta } from "../../config/reuserFunctions";
import DetallesEmpresa from "./DetallesEmpresa";
import { CustomChipStatus } from "./CustomComp";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "75vh",
  },
});

export default function TablaEmpresas({
  data,
  page,
  setPage,
  limit,
  refetch,
  setAlert,
}) {
  const classes = useStyles();

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <React.Fragment>
      <Paper className={classes.root} variant="outlined">
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: 0, margin: 0 }} />
                <TableCell>Status</TableCell>
                <TableCell>Fecha Registro</TableCell>
                <TableCell>Nombre Empresa</TableCell>
                <TableCell>Nombre Propietario</TableCell>
                <TableCell>Correo</TableCell>
                <TableCell>Telefono</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.docs.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                    onClick={() => {
                      document.getElementById(`fake-button-${row._id}`).click();
                    }}
                    style={{height: 48}}
                  >
                    <TableCell style={{ padding: 0, margin: 0 }}>
                      <DetallesEmpresa
                        data={row}
                        refetch={refetch}
                        setAlert={setAlert}
                      />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <CustomChipStatus status={row.empresa_activa} />
                    </TableCell>
                    <TableCell>
                      {formatoFechaCorta(row.fecha_registro)}
                    </TableCell>
                    <TableCell>{row.nombre_empresa}</TableCell>
                    <TableCell>{row.nombre_dueno}</TableCell>
                    <TableCell>{row.correo_empresa}</TableCell>
                    <TableCell>{row.telefono_dueno}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.totalDocs}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </React.Fragment>
  );
}
