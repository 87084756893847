import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ActivarTienda from "../ActivarTienda";
import ActivarEmpresa from "../ActivarEmpresa";
import { makeStyles } from "@material-ui/core";
import ClearActions from "../ClearActions";

const useStyles = makeStyles({
  card: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#f0f4f8",
    color: "#486581",
    borderRadius: "5px",
    transition: ".3s ease",
    border: "1px solid #f0f4f8",
    "&:hover": {
      border: "1px solid #bcccdc",
    },
  },
  cardDanger: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#FDECEA",
    color: "#F44336",
    borderRadius: "5px",
    transition: ".3s ease",
    border: "1px solid #FDECEA",
    "&:hover": {
      border: "1px solid #F44336",
    },
  },
});

export default function DengerZone({ data, refetch, setAlert }) {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Typography variant="h6">
        <b>Configuración</b>
      </Typography>
      <Grid container spacing={1} style={{ padding: 18 }}>
        <Grid item md={3} style={{ flexGrow: 1 }}>
          <Box className={classes.card}>
            <Typography>
              Tienda: <b>{data.tienda ? "activa" : "inactiva"}</b>
            </Typography>
            <ActivarTienda
              empresa={data}
              refetch={refetch}
              setAlert={setAlert}
            />
          </Box>
        </Grid>
        <Grid item md={3} style={{ flexGrow: 1 }}>
          <Box className={classes.card}>
            <Typography>
              Empresa: <b>{data.empresa_activa ? "activa" : "inactiva"}</b>
            </Typography>
            <ActivarEmpresa
              empresa={data}
              refetch={refetch}
              setAlert={setAlert}
            />
          </Box>
        </Grid>
        <Grid item md={3} style={{ flexGrow: 1 }}>
          <Box className={classes.cardDanger}>
            <Typography>
              <b>Borrar</b>
            </Typography>
            <ClearActions datos={data} refetch={refetch} setAlert={setAlert} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
