import React from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Paper,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { gql, useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import cafiLogo from "../../img/Cafi.svg";
import { Launch } from "@material-ui/icons";
import IMG_LOGIN from "../../img/login.png";
import SnackBarMessages from "../SnackBarMessages";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: "none",
  },
  containerImagen: {
    maxWidth: "100%",
    maxHeight: 150,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  imagen: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image_logo: {
    backgroundImage: `url(${IMG_LOGIN})`,
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
  },
}));

const LogearPanelAdmin = gql`
  mutation logearPanelAdmin($input: LogearUsuarioInput) {
    logearPanelAdmin(input: $input) {
      token
    }
  }
`;

export default function LayoutLogin(props) {
  const classes = useStyles();
  const sesion = sessionStorage.getItem("sesionCafi");
  const [datos, setDatos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });
  const [showPassword, setShowPassword] = React.useState(false);

  if (sesion) props.history.push("/");

  const [logearPanelAdmin] = useMutation(LogearPanelAdmin);

  const signin = async (e) => {
    e.preventDefault();
    if (!datos.numero_usuario || !datos.password) {
      setAlert({
        message: "Los campos estan vacíos",
        status: "error",
        open: true,
      });
      return;
    }
    setLoading(true);

    try {
      const result = await logearPanelAdmin({
        variables: {
          input: {
            numero_usuario: datos.numero_usuario.toUpperCase(),
            password: datos.password,
          },
        },
      });
      const { token } = result.data.logearPanelAdmin;
      const decoded = jwt_decode(token);
      setLoading(false);
      sessionStorage.setItem("sesionCafi", JSON.stringify(decoded));
      sessionStorage.setItem("tokenCafi", JSON.stringify(token));
      props.history.push("/");
    } catch (error) {
      setAlert({ message: error.message, status: "error", open: true });
      setLoading(false);
    }
  };

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setDatos({
      ...datos,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box right={5} pt={1} px={5} position="absolute">
        <Button
          size="medium"
          startIcon={<Launch />}
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
          href="https://cafipuntodeventa.com/"
          target="_blank"
        >
          CAFI Punto de venta
        </Button>
      </Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Container maxWidth="xs">
          <Paper style={{ padding: 20 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              justifyItems="center"
            >
              <Box className={classes.containerImagen} textAlign="center">
                <img
                  className={classes.imagen}
                  src={cafiLogo}
                  alt="imagen no disponible"
                />
              </Box>
            </Box>
            <form autocomplete="on" onSubmit={signin}>
              <TextField
                label="Numero de usuario o Username"
                variant="outlined"
                name="numero_usuario"
                fullWidth
                className={classes.margin}
                onChange={obtenerCampos}
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                autoComplete="new-password"
              />

              <FormControl
                variant="outlined"
                name="password"
                className={classes.margin}
                fullWidth
              >
                <InputLabel htmlFor="usuario-password">Contraseña</InputLabel>
                <OutlinedInput
                  autoComplete="new-password"
                  name="password"
                  id="usuario-password"
                  type={showPassword ? "text" : "password"}
                  onChange={obtenerCampos}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <Box textAlign="center">
                <Button
                  size="large"
                  variant="contained"
                  disableElevation
                  className={classes.margin}
                  color="primary"
                  type="submit"
                  endIcon={
                    loading ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : null
                  }
                >
                  Entrar
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
}
