import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Add } from "@material-ui/icons";
import TablaEmpresas from "./TablaEmpresas";
import { useDebounce } from "use-debounce/lib";
import { useQuery } from "@apollo/client";
import { OBTENER_EMPREASAS } from "../../gql/Empresa/empresa";
import { CircularProgress, Typography } from "@material-ui/core";
import ErrorPage from "../../components/ErrorPage";
import RegistroEmpresa from "./RegistroEmpresa";
import SnackBarMessages from "../../components/SnackBarMessages";
import Notificar from "../Notificar";

function Empresas() {
  const [filtro, setFiltro] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFiltro = (e) => {
    setFiltro(e.target.value);
  };
  return (
    <Container maxWidth="xl">
      <Box my={1} display="flex" alignItems="center" gridGap={10}>
        <TextField
          label="Buscar por nombre, correo, propietario..."
          variant="outlined"
          size="small"
          onChange={handleChangeFiltro}
          style={{minWidth: 360}}
        />
        <Notificar />
        <Button
          variant="text"
          color="primary"
          style={{ textTransform: "none" }}
          startIcon={<Add />}
          onClick={handleClickOpen}
        >
          Nueva empresa
        </Button>
      </Box>
      <ListaEmpresas filtro={filtro} open={open} handleClose={handleClose} />
    </Container>
  );
}

const ListaEmpresas = ({ filtro, open, handleClose }) => {
  const limit = 20;
  const [page, setPage] = useState(0);
  const [value] = useDebounce(filtro, 500);
  const [alert, setAlert] = useState({
    status: "",
    message: "",
    open: false,
  });

  React.useEffect(() => {
    setPage(0);
  }, [value]);

  /* Queries */
  const { loading, data, error, refetch } = useQuery(OBTENER_EMPREASAS, {
    variables: {
      filtro: value,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
    //pollInterval: 5000
  });

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
        <Typography variant="h6">Cargando...</Typography>
      </Box>
    );
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  const { obtenerEmpresas } = data;

  return (
    <div>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <RegistroEmpresa
        refetch={refetch}
        open={open}
        handleClose={handleClose}
        setAlert={setAlert}
      />
      <TablaEmpresas
        data={obtenerEmpresas}
        page={page}
        setPage={setPage}
        limit={limit}
        refetch={refetch}
        setAlert={setAlert}
      />
    </div>
  );
};

export default Empresas;
