import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { Update } from "@material-ui/icons";
import NotificationList from "./NotificationsList";
import FormNoti from "./Form";
import { GET_UPDATES } from "../../gql/Updates/Updates";
import { useQuery } from "@apollo/client";
import SnackBarMessages from "../../components/SnackBarMessages";

export default function Notificar() {
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState({
    status: "",
    message: "",
    open: false,
  });

  const { data, loading, error, refetch } = useQuery(GET_UPDATES, {
    fetchPolicy: "network-only",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        startIcon={<Update />}
        color="primary"
        onClick={handleClickOpen}
        style={{ textTransform: "none" }}
      >
        Versiones CAFI
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title-noti"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="dialog-title-noti">
          {"Notificar Nueva Version CAFI"}
        </DialogTitle>
        <DialogContent>
        <SnackBarMessages alert={alert} setAlert={setAlert} />
          <Grid container spacing={2}>
            <Grid item md={7}>
              <FormNoti refetch={refetch} setAlert={setAlert} />
            </Grid>
            <Grid item md={5}>
              <NotificationList
                data={data}
                loading={loading}
                error={error}
                refetch={refetch}
                setAlert={setAlert}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
