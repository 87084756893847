import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { CheckCircle } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import RecargaFolios from "./RecargaFolios";

export default function DetallesFacturacion({ data, refetch, setAlert }) {
  return (
    <Box mt={2}>
      <Typography variant="h6">
        <b>Facturación</b>
      </Typography>
      <Grid container spacing={1}>
        <Grid item md={7} style={{ flexGrow: 1 }}>
          <Typography>
            <b>Nombre fiscal: </b>
            {` ${data.nombre_fiscal}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <b>Régimen fiscal: </b>
            {` ${data.regimen_fiscal}`}
          </Typography>
        </Grid>
        {data.sello_sat ? (
          <>
            <Grid item>
              <Typography
                compoent={Box}
                style={{ display: "flex", alignItems: "center" }}
              >
                <b>Cer: </b>
                {data.nombre_cer ? <CheckCircle htmlColor={green[500]} /> : "-"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                compoent={Box}
                style={{ display: "flex", alignItems: "center" }}
              >
                <b>Key: </b>
                {data.nombre_cer ? <CheckCircle htmlColor={green[500]} /> : "-"}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography>No hay sellos digitales cargados</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Typography>
            <b>RFC: </b>
            {` ${data.rfc}`}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2} ml={3}>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography>Folios</Typography>
          <Box mx={1} />
          <RecargaFolios empresa={data} refetch={refetch} setAlert={setAlert} />
        </Box>
        <Divider />
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography>
              <b>Usados: </b>
              {` ${data?.timbres_usados}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Restantes: </b>
              {` ${data?.limite_timbres - data?.timbres_usados}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <b>Límite: </b>
              {` ${data?.limite_timbres}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
